import React from 'react';
import Form from 'react-bootstrap/Form';
import FormGroup from 'react-bootstrap/FormGroup';
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';
import { useNavigate } from 'react-router-dom';

const Search: React.FC = () => {
  const navigate = useNavigate();

  return (
    <Form>
      <Row>
        <Col xl={{ span: 6, offset: 3 }} lg={{ span: 6, offset: 3 }} md={{ span: 8, offset: 2 }} sm={12}>
          <FormGroup controlId="term">
            <Form.Control
              autoFocus
              size="lg"
              type="text"
              name="term"
              placeholder="search for article, employee or shortcut..."
              value=""
              onChange={(event) => navigate(`/search?term=${event.target.value}`)}
            />
          </FormGroup>
        </Col>
      </Row>
    </Form>
  );
};

export default Search;
