import React from 'react';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import SEO from '../components/SEO';
import PaySquare from '../images/paysquare.png';

const Lunch: React.FC = () => {
  return (
    <SEO title="Lunch" description="">
      <Container>
        <Row>
          <Col>
            <p className="lead">You can find the menu on this <a href="https://docs.google.com/spreadsheets/d/1VxqV8YVOv7UWd8LjOb9Tv1BWhK2wp1YdlrVrCKxIoX8/edit#gid=1770083576" target="_blank" rel="noopener noreferrer">link</a></p>
          </Col>
        </Row>
        <Row>
          <Col className="text-center">
            <img src={PaySquare} alt="pay by square" />
          </Col>
        </Row>
      </Container>
    </SEO>
  );
};

export default Lunch;
