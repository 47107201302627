import React from 'react';
import Card from 'react-bootstrap/Card';
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';
import { useLazyLoadQuery } from 'react-relay';
import { graphql } from 'babel-plugin-relay/macro';
import LinkImage from '../../images/link.png';

const Shortcuts: React.FC = (props: any) => {
  const onShortcutClick = (url: string) => {
    const link = document.createElement("a");
    link.referrerPolicy = "no-referrer";
    link.rel = "noreferrer";
    link.target = "_blank";
    link.href = url;
    link.click();
  };

  return (
    <>
      <Row>
      {
        props.shortcuts.edges.map((edge: any) => edge.node).map((shortcut: any) => (
          <Col key={shortcut.id} lg="2" md="2" sm="3" xs="6" className="mt-4 mb-4" onClick={() => onShortcutClick(shortcut.url)} style={{ cursor: 'pointer' }}>
            <Card>
              <div className="text-center" style={{ paddingTop: '1rem' }}>
                <Card.Img variant="top" src={LinkImage} style={{ maxWidth: 64 }} />
              </div>
              <Card.Body>
                <Card.Title className="text-center">{shortcut.name}</Card.Title>
              </Card.Body>
            </Card>
          </Col>
        ))
      }
      </Row>
    </>
  );
};

const HomeShortcutsContainer = () => {
  const data = useLazyLoadQuery<any>(
    graphql`
      query HomeShortcutsContainerQuery(
        $first: Int) {
        shortcuts(first: $first) {
          edges  {
            node {
              id
              _id
              name
              url
            }
          }
        }
      }`, {
        first: 6,
        after: null,
    });
  
  return (
    <Shortcuts {...data} />
  );
};

export default HomeShortcutsContainer;
