import React, { useState, useEffect, Suspense } from 'react';
import { usePaginationFragment, useLazyLoadQuery } from 'react-relay';
import { graphql } from 'babel-plugin-relay/macro';
import Card from 'react-bootstrap/Card';
import Col from 'react-bootstrap/Col';
import { Link } from 'react-router-dom';
import Row from 'react-bootstrap/Row';
import Filter from './Filter';
import { getQueryParameter } from '../../utils/functions';
import LinkImage from '../../images/link.png';

interface Props {
  query: any;
  values: any;
}

const SearchResults: React.FC<Props> = (props: Props) => {
  const values = props.values;
  const { data, refetch } = usePaginationFragment<any, any>(
    graphql`
      fragment SearchContainer_search on Query
      @refetchable(queryName: "SearchContainerResultsQuery") {
        search (
          first: $first
          after: $after
          term: $term
        ) @connection(key: "SearchList_search") {
          totalCount
          edges  {
            node {
              __typename
              ... on Article {
                id
                _id
                title
                description
              }
              ... on Employee {
                id
                _id
                firstName
                lastName
                phone
                email
              }
              ... on Shortcut {
                id
                _id
                name
                url
              }
            }
          }
          pageInfo {
            startCursor
            endCursor
            hasNextPage
            hasPreviousPage
          }
        }
      }`,
    props.query
  );

  useEffect(() => {
    refetch(values);
  }, [ values, refetch ]);

  return (
    <>
      {
        data.search.edges.map((edge: any) => edge.node).map((node: any) => {
          if (node.__typename === 'Article') {
            return (
              <Row key={node.id}>
                <Col lg="3" md="4" sm="4" xs="12" className="mt-4 mb-4">
                  <h3>{node.title}</h3>
                  <p className="small">{node.createdAt}</p>
                  <p>{node.description}</p>
                  <Link className="btn btn-outline-primary" role="button" to={`/article/${node._id}`}>Read more</Link>
                </Col>
              </Row>
            );
          }

          if (node.__typename === 'Employee') {
            return (
              <Row key={node.id}>
                <Col lg="3" md="4" sm="4" xs="12" className="mt-4 mb-4">
                  <Card>
                    <Card.Body>
                      <Card.Title>{node.firstName} {node.lastName}</Card.Title>
                      <Card.Subtitle className="mb-2 text-muted">{node.position}</Card.Subtitle>
                      <p>
                        <Card.Link href={`tel:${node.phone}`}>{node.phone}</Card.Link>
                      </p>
                      <p>
                        <Card.Link href={`mailto:${node.email}`}>{node.email}</Card.Link>
                      </p>
                    </Card.Body>
                  </Card>
                </Col>
              </Row>
            );
          }

          if (node.__typename === 'Shortcut') {
            return (
              <Row key={node.id}>
                <Col lg="2" md="2" sm="3" xs="12" className="mt-4 mb-4" style={{ cursor: 'pointer' }}>
                  <Card>
                    <div className="text-center" style={{ paddingTop: '1rem' }}>
                      <Card.Img variant="top" src={LinkImage} style={{ maxWidth: 64 }} />
                    </div>
                    <Card.Body>
                      <Card.Title className="text-center">{node.name}</Card.Title>
                    </Card.Body>
                  </Card>
                </Col>
              </Row>
            );
          }
        })
      }
    </>
  );
};

const SearchContainer = () => {
  const term = getQueryParameter('term');


  const [values, setValues] = useState({
    term: term !== '' ? term : null,
  });

  const query = useLazyLoadQuery<any>(
    graphql`
      query SearchContainerQuery(
        $first: Int,
        $after: String,
        $term: String) {
        ...SearchContainer_search
      }`, {
        first: 3,
        after: null,
        ...values,
    });
  
  return (
    <>
      <div style={{ marginTop: 20, marginBottom: 20 }}>
        <Filter onUpdate={(values: any) => setValues(values)} />
      </div>
      <Suspense fallback={<p>Loading...</p>}>
        <SearchResults query={query} values={values} />
      </Suspense>
    </>
  );
};

export default SearchContainer;
