/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest } from "relay-runtime";

import { FragmentRefs } from "relay-runtime";
export type ArticlesOrderField = "CREATED_AT" | "TITLE" | "%future added value";
export type Direction = "ASC" | "DESC" | "%future added value";
export type ArticlesOrder = {
    field: ArticlesOrderField;
    direction: Direction;
};
export type ArticlesContainerArticlesQueryVariables = {
    after?: string | null | undefined;
    first?: number | null | undefined;
    orderBy?: Array<ArticlesOrder | null> | null | undefined;
};
export type ArticlesContainerArticlesQueryResponse = {
    readonly " $fragmentRefs": FragmentRefs<"ArticlesContainer_articles">;
};
export type ArticlesContainerArticlesQuery = {
    readonly response: ArticlesContainerArticlesQueryResponse;
    readonly variables: ArticlesContainerArticlesQueryVariables;
};



/*
query ArticlesContainerArticlesQuery(
  $after: String
  $first: Int
  $orderBy: [ArticlesOrder]
) {
  ...ArticlesContainer_articles
}

fragment ArticlesContainer_articles on Query {
  articles(first: $first, after: $after, orderBy: $orderBy) {
    totalCount
    edges {
      node {
        id
        _id
        title
        description
        createdAt
        __typename
      }
      cursor
    }
    pageInfo {
      startCursor
      endCursor
      hasNextPage
      hasPreviousPage
    }
  }
}
*/

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "after"
  },
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "first"
  },
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "orderBy"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "after",
    "variableName": "after"
  },
  {
    "kind": "Variable",
    "name": "first",
    "variableName": "first"
  },
  {
    "kind": "Variable",
    "name": "orderBy",
    "variableName": "orderBy"
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "ArticlesContainerArticlesQuery",
    "selections": [
      {
        "args": null,
        "kind": "FragmentSpread",
        "name": "ArticlesContainer_articles"
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "ArticlesContainerArticlesQuery",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "ArticleConnection",
        "kind": "LinkedField",
        "name": "articles",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "totalCount",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "ArticleEdge",
            "kind": "LinkedField",
            "name": "edges",
            "plural": true,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "Article",
                "kind": "LinkedField",
                "name": "node",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "id",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "_id",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "title",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "description",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "createdAt",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "__typename",
                    "storageKey": null
                  }
                ],
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "cursor",
                "storageKey": null
              }
            ],
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "PageInfo",
            "kind": "LinkedField",
            "name": "pageInfo",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "startCursor",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "endCursor",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "hasNextPage",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "hasPreviousPage",
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      },
      {
        "alias": null,
        "args": (v1/*: any*/),
        "filters": [
          "orderBy"
        ],
        "handle": "connection",
        "key": "ArticleList_articles",
        "kind": "LinkedHandle",
        "name": "articles"
      }
    ]
  },
  "params": {
    "cacheID": "2b26e5ee83521936e8601df0c1382174",
    "id": null,
    "metadata": {},
    "name": "ArticlesContainerArticlesQuery",
    "operationKind": "query",
    "text": "query ArticlesContainerArticlesQuery(\n  $after: String\n  $first: Int\n  $orderBy: [ArticlesOrder]\n) {\n  ...ArticlesContainer_articles\n}\n\nfragment ArticlesContainer_articles on Query {\n  articles(first: $first, after: $after, orderBy: $orderBy) {\n    totalCount\n    edges {\n      node {\n        id\n        _id\n        title\n        description\n        createdAt\n        __typename\n      }\n      cursor\n    }\n    pageInfo {\n      startCursor\n      endCursor\n      hasNextPage\n      hasPreviousPage\n    }\n  }\n}\n"
  }
};
})();
(node as any).hash = 'a24f896aca398b2e3d94cf9ddfbfc82f';
export default node;
