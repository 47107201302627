import React from 'react';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import SEO from '../components/SEO';
import Slides from './presentation/Slides';

const Home: React.FC = () => {
  return (
    <SEO title="Presentation" description="">
      <Container fluid>
        <Row>
          <Col>
            <Slides />
          </Col>
        </Row>
      </Container>
    </SEO>
  );
};

export default Home;
