import React from 'react';
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';
import { useLazyLoadQuery } from 'react-relay';
import { graphql } from 'babel-plugin-relay/macro';
import { Link } from 'react-router-dom';

const Articles: React.FC = (props: any) => {
  return (
    <>
      <Row>
      {
        props.articles.edges.map((edge: any) => edge.node).map((article: any) => (
          <Col key={article.id} lg="3" md="4" sm="4" xs="12" className="mt-4 mb-4">
            <h3>{article.title}</h3>
            <p className="small">{article.createdAt}</p>
            <p>{article.description}</p>
            <Link className="btn btn-outline-primary" role="button" to={`/article/${article._id}`}>Read more</Link>
          </Col>
        ))
      }
      </Row>
    </>
  );
};

const HomeArticlesContainer = () => {
  const data = useLazyLoadQuery<any>(
    graphql`
      query HomeArticlesContainerQuery(
        $first: Int,
        $orderBy: [ArticlesOrder]) {
          articles(first: $first, orderBy: $orderBy) {
            edges  {
              node {
                id
                _id
                title
                description
                createdAt
              }
            }
          }
      }`, {
        first: 4,
        orderBy: [{ field: 'CREATED_AT', direction: 'DESC' }],
    });
  
  return (
    <Articles {...data} />
  );
};

export default HomeArticlesContainer;
