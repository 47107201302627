import React, { useState, useEffect, Suspense } from 'react';
import Card from 'react-bootstrap/Card';
import Col from 'react-bootstrap/Col';
import Button from 'react-bootstrap/Button';
import Row from 'react-bootstrap/Row';
import { usePaginationFragment, useLazyLoadQuery } from 'react-relay';
import { graphql } from 'babel-plugin-relay/macro';
import LinkImage from '../../images/link.png';

interface Props {
  query: any;
  values: any;
}

const Employees: React.FC<Props> = (props: Props) => {
  const [ redirectId, setRedirectId ] = useState('');

  const onAccountClick = (id: string) => {
    setRedirectId(id);
  };

  const values = props.values;
  const { data, loadNext, hasNext, refetch } = usePaginationFragment<any, any>(
    graphql`
      fragment EmployeesContainer_employees on Query
      @refetchable(queryName: "EmployeesContainerEmployeesQuery") {
        employees (
          first: $first
          after: $after
        ) @connection(key: "EmployeeList_employees") {
          totalCount
          edges  {
            node {
              id
              _id
              firstName
              lastName
              email
              phone
              position
            }
          }
          pageInfo {
            startCursor
            endCursor
            hasNextPage
            hasPreviousPage
          }
        }
      }`,
    props.query
  );

  useEffect(() => {
    refetch(values);
  }, [ values, refetch ]);

  return (
    <>
      <Row>
      {
        data.employees.edges.map((edge: any) => edge.node).map((employee: any) => (
          <Col key={employee.id} lg="3" md="4" sm="4" xs="12" className="mt-4 mb-4">
            <Card>
              <Card.Body>
                <Card.Title>{employee.firstName} {employee.lastName}</Card.Title>
                <Card.Subtitle className="mb-2 text-muted">{employee.position}</Card.Subtitle>
                <p>
                  <Card.Link href={`tel:${employee.phone}`}>{employee.phone}</Card.Link>
                </p>
                <p>
                  <Card.Link href={`mailto:${employee.email}`}>{employee.email}</Card.Link>
                </p>
              </Card.Body>
            </Card>
          </Col>
        ))
      }
      </Row>
      {
        hasNext &&
        <Button onClick={() => loadNext(20)}>Load more</Button>
      }
    </>
  );
};

const EmployeesContainer = () => {
  const query = useLazyLoadQuery<any>(
    graphql`
      query EmployeesContainerQuery(
        $first: Int,
        $after: String) {
        ...EmployeesContainer_employees
      }`, {
        first: 20,
        after: null,
    });
  
  return (
    <>
      <Suspense fallback={<p>Loading...</p>}>
        <Employees query={query} values={{}} />
      </Suspense>
    </>
  );
};

export default EmployeesContainer;
