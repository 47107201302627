/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest } from "relay-runtime";

export type ArticlesOrderField = "CREATED_AT" | "TITLE" | "%future added value";
export type Direction = "ASC" | "DESC" | "%future added value";
export type ArticlesOrder = {
    field: ArticlesOrderField;
    direction: Direction;
};
export type HomeArticlesContainerQueryVariables = {
    first?: number | null | undefined;
    orderBy?: Array<ArticlesOrder | null> | null | undefined;
};
export type HomeArticlesContainerQueryResponse = {
    readonly articles: {
        readonly edges: ReadonlyArray<{
            readonly node: {
                readonly id: string;
                readonly _id: string;
                readonly title: string;
                readonly description: string;
                readonly createdAt: unknown;
            } | null;
        } | null> | null;
    } | null;
};
export type HomeArticlesContainerQuery = {
    readonly response: HomeArticlesContainerQueryResponse;
    readonly variables: HomeArticlesContainerQueryVariables;
};



/*
query HomeArticlesContainerQuery(
  $first: Int
  $orderBy: [ArticlesOrder]
) {
  articles(first: $first, orderBy: $orderBy) {
    edges {
      node {
        id
        _id
        title
        description
        createdAt
      }
    }
  }
}
*/

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "first"
  },
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "orderBy"
  }
],
v1 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "first",
        "variableName": "first"
      },
      {
        "kind": "Variable",
        "name": "orderBy",
        "variableName": "orderBy"
      }
    ],
    "concreteType": "ArticleConnection",
    "kind": "LinkedField",
    "name": "articles",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "ArticleEdge",
        "kind": "LinkedField",
        "name": "edges",
        "plural": true,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "Article",
            "kind": "LinkedField",
            "name": "node",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "id",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "_id",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "title",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "description",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "createdAt",
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "HomeArticlesContainerQuery",
    "selections": (v1/*: any*/),
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "HomeArticlesContainerQuery",
    "selections": (v1/*: any*/)
  },
  "params": {
    "cacheID": "a79696512fe5d8c3351b522cdd7cc6ab",
    "id": null,
    "metadata": {},
    "name": "HomeArticlesContainerQuery",
    "operationKind": "query",
    "text": "query HomeArticlesContainerQuery(\n  $first: Int\n  $orderBy: [ArticlesOrder]\n) {\n  articles(first: $first, orderBy: $orderBy) {\n    edges {\n      node {\n        id\n        _id\n        title\n        description\n        createdAt\n      }\n    }\n  }\n}\n"
  }
};
})();
(node as any).hash = '333cb8af6cd60a312cd1fadefa0f2cf5';
export default node;
