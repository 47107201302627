import React, { Suspense } from 'react';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import SEO from '../components/SEO';
import News from './home/HomeArticles';
import Shortcuts from './home/HomeShortcuts';
import Search from './home/Search';
import KontentinoLogo from '../images/kontentino.png';

const Home: React.FC = () => {
  return (
    <SEO title="" description="">
      <Container>
        <Row>
          <Col className="text-center">
            <img src={KontentinoLogo} alt="Kontentino logo" style={{ maxWidth: '100%' }} />
          </Col>
        </Row>
        <Row className="mt-5">
          <Col>
            <Search />
          </Col>
        </Row>
        <Row style={{ marginTop: '10rem' }}>
          <Col>
            <Suspense fallback={<p>Loading...</p>}>
              <Shortcuts />
            </Suspense>
          </Col>
        </Row>
        <Row>
          <Col>
            <Suspense fallback={<p>Loading...</p>}>
              <News />
            </Suspense>
          </Col>
        </Row>
      </Container>
    </SEO>
  );
};

export default Home;
