import React from 'react';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import SEO from '../components/SEO';
import News from './articles/News';

const Articles: React.FC = () => {
  return (
    <SEO title="Articles" description="">
      <Container>
        <Row>
          <Col>
            <h1 className="text-center">Articles</h1>
          </Col>
        </Row>
        <Row>
          <Col>
            <News />
          </Col>
        </Row>
      </Container>
    </SEO>
  );
};

export default Articles;
