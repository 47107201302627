import React from 'react';
import Container from 'react-bootstrap/Container';
import Navbar from 'react-bootstrap/Navbar';
import Nav from 'react-bootstrap/Nav';
import NavDropdown from 'react-bootstrap/NavDropdown';
import Form from 'react-bootstrap/Form';
import FormControl from 'react-bootstrap/FormControl';
import { Link, useNavigate } from 'react-router-dom';
import favicon from '../images/favicon.png';

const Navigation = () => {
  const navigate = useNavigate();
  return (
    <Container fluid>
      <Navbar bg="light" variant="light" expand="lg">
        <Navbar.Brand as={Link} to="/">
          <img
            alt="intranet"
            src={favicon}
            width="30"
            height="30"
            className="d-inline-block align-top"
          />{' '}
          Intranet
        </Navbar.Brand>
        <Navbar.Toggle aria-controls="basic-navbar-nav" />
        <Navbar.Collapse id="basic-navbar-nav">
          <Nav className="me-auto">
            <NavDropdown title="Manage" id="collasible-nav-dropdown">
              <NavDropdown.Item as={Link} to="/manage/articles">Articles</NavDropdown.Item>
              <NavDropdown.Item as={Link} to="/manage/employees">Employees</NavDropdown.Item>
              <NavDropdown.Item as={Link} to="/manage/shortcuts">Shortcuts</NavDropdown.Item>
            </NavDropdown>
            <Nav.Link as={Link} to="/articles">Articles</Nav.Link>
            <Nav.Link as={Link} to="/employees">Employees</Nav.Link>
            <Nav.Link as={Link} to="/shortcuts">Shortcuts</Nav.Link>
            <Nav.Link as={Link} to="/presentation">Presentation</Nav.Link>
            <Nav.Link as={Link} to="/lunch">Lunch</Nav.Link>
          </Nav>
          <Form className="d-flex">
            <FormControl
              type="search"
              placeholder="Search"
              className="me-2"
              aria-label="Search"
              value=""
              onChange={(event) => navigate(`/search?term=${event.target.value}`)}
            />
          </Form>
        </Navbar.Collapse>
      </Navbar>
    </Container>
  );
};

export default React.memo(Navigation);
