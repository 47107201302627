import React, {useState} from 'react';
import Form from 'react-bootstrap/Form';
import FormGroup from 'react-bootstrap/FormGroup';
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';
import { getQueryParameter, setQueryParameter } from '../../utils/functions';

interface Props {
  onUpdate: Function;
}

const Filter: React.FC<Props> = (props: Props) => {
  const [values, setValues] = useState({
    term: getQueryParameter('term'),
  });

  const handleChange = (e: any) => {
    const { name, value } = e.target;

    setValues({
      ...values,
      [name]: value,
    });
    setQueryParameter(name, value);
    update({ [name]: value.length === 0 ? null : value });
  };

  const update = (value: any) => {
    props.onUpdate({
      ...{
        term: values.term.length === 0 ? null : values.term,
      }, ...value
    });
  };

  return (
    <Form>
      <Row>
        <Col xl={4} lg={4} md={6} sm={12}>
          <FormGroup controlId="term">
            <Form.Label>Search:</Form.Label>
            <Form.Control
              autoFocus
              type="text"
              name="term"
              placeholder=""
              value={values.term}
              onChange={handleChange}
            />
          </FormGroup>
        </Col>
      </Row>
    </Form>
  );
};

export default Filter;
