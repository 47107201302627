import React from 'react';
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import {  } from 'react-router';
import { RelayEnvironmentProvider } from 'react-relay';
import environment from './environment';
import Navigation from './components/Navigation';
import Footer from './components/Footer';
import Home from './pages/Home';
import Articles from './pages/Articles';
import Employees from './pages/Employees';
import Shortcuts from './pages/Shortcuts';
import Presentation from './pages/Presentation';
import Lunch from './pages/Lunch';
import Article from './pages/Article';
import Search from './pages/Search';
import EditArticle from './pages/manage/Article';
import ManageArticles from './pages/manage/Articles';
import PageNotFound from './pages/PageNotFound';

function App() {
  return (
    <RelayEnvironmentProvider environment={environment}>
      <BrowserRouter>
        <>
          <Navigation />

          <main role="main" className="flex-shrink-0" style={{ marginTop: 25, marginBottom: 25, minHeight: 'calc(100vh - 162px)' }}>
            
              <Routes>
                <Route path="/" element={<Home />} />
                <Route path="/articles" element={<Articles />} />
                <Route path="/employees" element={<Employees />} />
                <Route path="/shortcuts" element={<Shortcuts />} />
                <Route path="/presentation" element={<Presentation />} />
                <Route path="/lunch" element={<Lunch />} />
                <Route path="/article/:id" element={<Article />} />
                <Route path="/search" element={<Search />} />
                <Route path="/manage/article" element={<EditArticle />} />
                <Route path="/manage/articles" element={<ManageArticles />} />

                <Route path="/" element={<PageNotFound />} />
              </Routes>
            
          </main>

          <Footer />
        </>
      </BrowserRouter>
    </RelayEnvironmentProvider>
  );
}

export default App;
