import React, { useState } from 'react';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import { EditorState } from 'draft-js';
import { Editor } from 'react-draft-wysiwyg';
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
import SEO from '../../components/SEO';

const Article: React.FC = () => {
  const [editorState, setEditorState] = useState(
    () => EditorState.createEmpty(),
  );

  return (
    <SEO title="Article" description="">
      <Container>
        <Row>
          <Col>
            <h1>Editor</h1>
          </Col>
        </Row>
        <Row>
          <Col>
            <Editor editorState={editorState as any} onEditorStateChange={setEditorState as any} />
          </Col>
        </Row>
      </Container>
    </SEO>
  );
};

export default Article;
