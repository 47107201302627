import React, { useEffect, Suspense } from 'react';
import Card from 'react-bootstrap/Card';
import Col from 'react-bootstrap/Col';
import Button from 'react-bootstrap/Button';
import Row from 'react-bootstrap/Row';
import { usePaginationFragment, useLazyLoadQuery } from 'react-relay';
import { graphql } from 'babel-plugin-relay/macro';
import LinkImage from '../../images/link.png';

interface Props {
  query: any;
  values: any;
}

const Shortcuts: React.FC<Props> = (props: Props) => {
  const onShortcutClick = (url: string) => {
    const link = document.createElement("a");
    link.referrerPolicy = "no-referrer";
    link.rel = "noreferrer";
    link.target = "_blank";
    link.href = url;
    link.click();
  };

  const values = props.values;
  const { data, loadNext, hasNext, refetch } = usePaginationFragment<any, any>(
    graphql`
      fragment ShortcutsContainer_shortcuts on Query
      @refetchable(queryName: "ShortcutsContainerShortcutsQuery") {
        shortcuts (
          first: $first
          after: $after
        ) @connection(key: "ShortcutList_shortcuts") {
          totalCount
          edges  {
            node {
              id
              _id
              name
              url
            }
          }
          pageInfo {
            startCursor
            endCursor
            hasNextPage
            hasPreviousPage
          }
        }
      }`,
    props.query
  );

  useEffect(() => {
    refetch(values);
  }, [ values, refetch ]);

  return (
    <>
      <Row>
      {
        data.shortcuts.edges.map((edge: any) => edge.node).map((shortcut: any) => (
          <Col key={shortcut.id} lg="2" md="2" sm="3" xs="6" className="mt-4 mb-4" onClick={() => onShortcutClick(shortcut.url)} style={{ cursor: 'pointer' }}>
            <Card>
              <div className="text-center" style={{ paddingTop: '1rem' }}>
                <Card.Img variant="top" src={LinkImage} style={{ maxWidth: 64 }} />
              </div>
              <Card.Body>
                <Card.Title className="text-center">{shortcut.name}</Card.Title>
              </Card.Body>
            </Card>
          </Col>
        ))
      }
      </Row>
      {
        hasNext &&
        <Button onClick={() => loadNext(20)}>Load more</Button>
      }
    </>
  );
};

const ShortcutsContainer = () => {
  const query = useLazyLoadQuery<any>(
    graphql`
      query ShortcutsContainerQuery(
        $first: Int,
        $after: String) {
        ...ShortcutsContainer_shortcuts
      }`, {
        first: 20,
        after: null,
    });
  
  return (
    <>
      <Suspense fallback={<p>Loading...</p>}>
        <Shortcuts query={query} values={{}} />
      </Suspense>
    </>
  );
};

export default ShortcutsContainer;
