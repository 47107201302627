import React from 'react';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import SEO from '../components/SEO';
import EmployeesContainer from './employees/Employees';

const Employees: React.FC = () => {
  return (
    <SEO title="Employees" description="">
      <Container>
        <Row>
          <Col>
          <h1 className="text-center">Employees</h1>
          </Col>
        </Row>
        <Row>
          <Col>
            <EmployeesContainer />
          </Col>
        </Row>
      </Container>
    </SEO>
  );
};

export default Employees;
