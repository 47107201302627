/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest } from "relay-runtime";

export type HomeShortcutsContainerQueryVariables = {
    first?: number | null | undefined;
};
export type HomeShortcutsContainerQueryResponse = {
    readonly shortcuts: {
        readonly edges: ReadonlyArray<{
            readonly node: {
                readonly id: string;
                readonly _id: string;
                readonly name: string;
                readonly url: string;
            } | null;
        } | null> | null;
    } | null;
};
export type HomeShortcutsContainerQuery = {
    readonly response: HomeShortcutsContainerQueryResponse;
    readonly variables: HomeShortcutsContainerQueryVariables;
};



/*
query HomeShortcutsContainerQuery(
  $first: Int
) {
  shortcuts(first: $first) {
    edges {
      node {
        id
        _id
        name
        url
      }
    }
  }
}
*/

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "first"
  }
],
v1 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "first",
        "variableName": "first"
      }
    ],
    "concreteType": "ShortcutConnection",
    "kind": "LinkedField",
    "name": "shortcuts",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "ShortcutEdge",
        "kind": "LinkedField",
        "name": "edges",
        "plural": true,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "Shortcut",
            "kind": "LinkedField",
            "name": "node",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "id",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "_id",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "name",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "url",
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "HomeShortcutsContainerQuery",
    "selections": (v1/*: any*/),
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "HomeShortcutsContainerQuery",
    "selections": (v1/*: any*/)
  },
  "params": {
    "cacheID": "5a4acce4e0776117d3703e88970b0dda",
    "id": null,
    "metadata": {},
    "name": "HomeShortcutsContainerQuery",
    "operationKind": "query",
    "text": "query HomeShortcutsContainerQuery(\n  $first: Int\n) {\n  shortcuts(first: $first) {\n    edges {\n      node {\n        id\n        _id\n        name\n        url\n      }\n    }\n  }\n}\n"
  }
};
})();
(node as any).hash = '1cb17dcac5de219dc45c9f9a349e878e';
export default node;
