import React, { useEffect, Suspense } from 'react';
import Container from 'react-bootstrap/Container';
import Col from 'react-bootstrap/Col';
import Button from 'react-bootstrap/Button';
import Row from 'react-bootstrap/Row';
import { usePaginationFragment, useLazyLoadQuery } from 'react-relay';
import { graphql } from 'babel-plugin-relay/macro';
import { Link } from 'react-router-dom';
import SEO from '../../components/SEO';

interface Props {
  query: any;
  values: any;
}

const Articles: React.FC<Props> = (props: Props) => {
  const values = props.values;
  const { data, loadNext, hasNext, refetch } = usePaginationFragment<any, any>(
    graphql`
      fragment ArticlesContainer_articles on Query
      @refetchable(queryName: "ArticlesContainerArticlesQuery") {
        articles (
          first: $first
          after: $after
          orderBy: $orderBy
        ) @connection(key: "ArticleList_articles") {
          totalCount
          edges  {
            node {
              id
              _id
              title
              description
              createdAt
            }
          }
          pageInfo {
            startCursor
            endCursor
            hasNextPage
            hasPreviousPage
          }
        }
      }`,
    props.query
  );

  useEffect(() => {
    refetch(values);
  }, [ values, refetch ]);

  return (
    <>
      <Row>
      {
        data.articles.edges.map((edge: any) => edge.node).map((article: any) => (
          <Col key={article.id} lg="3" md="4" sm="4" xs="12" className="mt-4 mb-4">
            <h3>{article.title}</h3>
            <p className="small">{article.createdAt}</p>
            <p>{article.description}</p>
            <Link className="btn btn-outline-primary" role="button" to={`/article/${article._id}`}>Read more</Link>
          </Col>
        ))
      }
      </Row>
      {
        hasNext &&
        <Button onClick={() => loadNext(20)}>Load more</Button>
      }
    </>
  );
};

const ArticlesContainer = () => {
  const query = useLazyLoadQuery<any>(
    graphql`
      query ArticlesContainerQuery(
        $first: Int,
        $after: String,
        $orderBy: [ArticlesOrder]) {
        ...ArticlesContainer_articles
      }`, {
        first: 20,
        after: null,
        orderBy: [{ field: 'CREATED_AT', direction: 'DESC' }],
    });
  
  return (
    <SEO title="Articles" description="">
      <Container>
        <Row>
          <Col>
          <h1 className="text-center">Articles</h1>
          </Col>
        </Row>
        <Row>
          <Col>
            <Suspense fallback={<p>Loading...</p>}>
              <Articles query={query} values={{}} />
            </Suspense>
          </Col>
        </Row>
      </Container>
    </SEO>
  );
};

export default ArticlesContainer;
