import React from 'react';
import Carousel from 'react-bootstrap/Carousel';

const Slides: React.FC = () => {
  return (
    <Carousel variant="dark">
      <Carousel.Item>
        <div className="d-block w-100" style={{ width: 1296, height: 648 }}></div>
        <h2 style={{ position: 'absolute', top: '5%', left: '50%', transform: 'translate(-50%, 0)', fontSize: '26rem' }}>13</h2>
        <Carousel.Caption>
          <h3>Registrations</h3>
          <p>Number of accounts created today.</p>
        </Carousel.Caption>
      </Carousel.Item>
      <Carousel.Item>
        <div className="d-block w-100" style={{ width: 1296, height: 648 }}></div>
        <h2 style={{ position: 'absolute', top: '5%', left: '50%', transform: 'translate(-50%, 0)', fontSize: '26rem' }}>3543</h2>
        <Carousel.Caption>
          <h3>MRR</h3>
          <p>Achieved MRR in this month.</p>
        </Carousel.Caption>
      </Carousel.Item>
      <Carousel.Item>
        <div className="d-block w-100" style={{ width: 1296, height: 648 }}></div>
        <p style={{ position: 'absolute', top: '5%', left: '50%', transform: 'translate(-50%, 0)', fontSize: '2rem' }}>
          <ul>
            <li>Ads planner</li>
            <li>Analytics</li>
            <li>New calendar</li>
          </ul>
        </p>
        <Carousel.Caption>
          <h3>Releases</h3>
          <p>What is going out this week.</p>
        </Carousel.Caption>
      </Carousel.Item>
      <Carousel.Item>
        <div className="d-block w-100" style={{ width: 1296, height: 648 }}></div>
        <div style={{ position: 'absolute', top: '5%', left: '50%', transform: 'translate(-50%, 0)' }}>
          <h3 className="text-center">Product job placeholder</h3>
          <p className="lead">Zoom meeting at 2:30 today who's the goto on this job with the way forward or strategic fit on-brand but completeley fresh. Those options are already baked in with this model blue money.</p>
          <h3 className="text-center">Marketing job placeholder</h3>
          <p className="lead">Wheelhouse optimize the fireball but run it up the flag pole. Move the needle optimize for search but what's the real problem we're trying to solve here? but parallel path critical mass driving the initiative forward.</p>
          <h3 className="text-center">CSM job placeholder</h3>
          <p className="lead">Cross functional teams enable out of the box brainstorming lean into that problem . Cross functional teams enable out of the box brainstorming 4-blocker we've bootstrapped the model so please submit the sop and uat files by next monday.</p>
        </div>
        <Carousel.Caption>
          <h3>Hiring</h3>
          <p>We are searching for these positions, do you know anyone?</p>
        </Carousel.Caption>
      </Carousel.Item>
      <Carousel.Item>
        <div className="d-block w-100" style={{ width: 1296, height: 648 }}></div>
        <Carousel.Caption style={{ color: 'black' }}>
          <h3>Monthly</h3>
          <p>Upcoming event.</p>
        </Carousel.Caption>
      </Carousel.Item>
      <Carousel.Item>
        <div className="d-block w-100" style={{ width: 1296, height: 648 }}></div>
        <Carousel.Caption style={{ color: 'black' }}>
          <h3>Alejandro</h3>
          <p>Next birthday.</p>
        </Carousel.Caption>
      </Carousel.Item>
    </Carousel>
  );
};

export default Slides;
