import React, { Suspense } from 'react';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import { useLazyLoadQuery } from 'react-relay';
import { graphql } from 'babel-plugin-relay/macro';
import { useParams } from "react-router-dom";
import SEO from '../components/SEO';
import { formatDate } from '../utils/functions';

const Article: React.FC<any> = ({ article }: any) => (
  <SEO title={article.title} description={article.description}>
    <Container>
      <Row>
        <Col>
          <h1>{article.title}</h1>
          <p className="small">{formatDate(new Date(article.createdAt), 'dd.MM.YYYY')}</p>
        </Col>
      </Row>
      <Row>
        <Col>
          <div dangerouslySetInnerHTML={{ __html: article.text }} />
        </Col>
      </Row>
    </Container>
  </SEO>
);

const ArticleContainer = () => {
  const params = useParams();

  const data = useLazyLoadQuery<any>(
    graphql`
      query ArticleQuery($id: ID!) {
        article(id: $id) {
          id
          _id
          title
          text
          createdAt
        }
      }`, {
      id: params.id,
    },
  );
  
  return (
    <Suspense fallback={<p>Loading...</p>}>
      <Article {...data} />
    </Suspense>
  );
};

const ArticleLoader = () => (
  <Suspense fallback={<p>Loading...</p>}>
    <ArticleContainer />
  </Suspense>
)

export default ArticleLoader;
